import { warehouseCode, calcularStockVendible } from "./utils";
import { getStocks } from "@suttyweb/hyperpop";

// https://www.javascripttutorial.net/dom/events/javascript-trigger-event/
function triggerEvent(el, type) {
  var e = document.createEvent("HTMLEvents");
  e.initEvent(type, false, true);
  el.dispatchEvent(e);
}

document.addEventListener("turbo:load", async () => {
  if (!window.headers) return;

  if (document.querySelectorAll("[data-quantity-input-sku]").length === 0) return;

  for (const inputEl of document.querySelectorAll(
    "[data-quantity-input-sku]"
  )) {
    inputEl.disabled = true;
  }

  const stocks = await getStocks(
    process.env.TANGO_HOST,
    window.headers,
    {
      warehouseCode,
    }
  );

  for (const inputEl of document.querySelectorAll(
    "[data-quantity-input-sku]"
  )) {
    let unidadesPorPallet = parseInt(inputEl.dataset.unidadesPorPallet);
    if (isNaN(unidadesPorPallet)) unidadesPorPallet = 1;

    const sku = inputEl.dataset.quantityInputSku;
    const stockObj = stocks.Data.find((stockObj) => stockObj.SKUCode === sku);
    const stock =
      stockObj && Math.floor(calcularStockVendible(stockObj, unidadesPorPallet) * 0.1);

    if (inputEl.value) {
      const quantity = parseInt(inputEl.value);
      // Si hay menos stock que la cantidad que se quiere comprar, bajar a lo que haya de stock
      inputEl.value = Math.min(quantity, stock);
    }

    if (stock && stock > 0) {
      inputEl.max = stock;
      inputEl.disabled = false;
    } else {
      inputEl.value = null;
      inputEl.placeholder = "No hay stock disponible";
    }

    for (const submitEl of document.querySelectorAll(`[data-submit="${sku}"]`)) {
      submitEl.disabled = !(stock && stock > 0);
    }

    // Recalcular carrito
    triggerEvent(inputEl, "input");
  }
});
